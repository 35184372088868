import { useEffect, useState } from 'react';
import './App.css';
import { createACSUser } from './utils/Auth';
import { initCallClient } from './utils/Call';
import { useAuthStore } from './store/useAuthStore';
import { shallow } from 'zustand/shallow';
import {
  Call,
  LocalVideoStream,
  RemoteParticipant,
  RemoteVideoStream,
  VideoStreamRenderer,
  VideoStreamRendererView,
} from '@azure/communication-calling';
import { useCallStore } from './store/useCallStore';

interface StateUpdate<T> {
  added: T[];
  removed: T[];
}

const App = () => {
  const localVideoContainer = document.getElementById('localVideoContainer');
  const remoteVideosGallery = document.getElementById('remoteVideoGallery');

  const [loading, setLoading] = useState<boolean>(false);

  const { displayName, accessToken, updateDisplayName } = useAuthStore(
    (state) => ({
      displayName: state.displayName,
      accessToken: state.accessToken,
      updateDisplayName: state.updateDisplayName,
    }),
    shallow
  );

  const {
    callAgent,
    deviceManager,
    call,
    callState,
    localVideoStream,
    localVideoStreamRenderer,
    groupCallId,
    isMuted,
    updateCall,
    updateCallState,
    updateLocalVideoStream,
    updateLocalVideoStreamRenderer,
  } = useCallStore(
    (state) => ({
      callAgent: state.callAgent,
      deviceManager: state.deviceManager,
      call: state.call,
      callState: state.callState,
      localVideoStream: state.localVideoStream,
      localVideoStreamRenderer: state.localVideoStreamRenderer,
      groupCallId: state.groupCallId,
      isMuted: state.isMuted,
      updateCall: state.updateCall,
      updateCallState: state.updateCallState,
      updateLocalVideoStream: state.updateLocalVideoStream,
      updateLocalVideoStreamRenderer: state.updateLocalVideoStreamRenderer,
    }),
    shallow
  );

  useEffect(() => {
    (async () => {
      if (!accessToken) await createACSUser();
    })();
  }, [accessToken]);

  const saveDisplayName = async (e: React.SyntheticEvent) => {
    setLoading(true);
    e.preventDefault();
    const target = e.target as typeof e.target & {
      displayName: { value: string };
    };
    updateDisplayName(target.displayName.value);
    await initCallClient();
    setLoading(false);
  };

  const joinGroupCallHandler = async () => {
    if (callAgent) {
      await deviceManager?.askDevicePermission({ video: true, audio: true });
      const call = callAgent?.join({ groupId: groupCallId });
      updateCall(call);
      subscribeToCall(call);
    }
  };

  const stateChangedCallback = (call: Call) => {
    if (call.state === 'Connected') {
      updateCallState('Connected');
    } else if (call.state === 'Disconnected') {
      updateCallState('Disconnected');
    }
  };

  const lvsUpdatedCallback = (e: StateUpdate<LocalVideoStream>) => {
    e.added.forEach(async (lvs: LocalVideoStream) => {
      updateLocalVideoStream(lvs);
      await displayLocalVideoStream(lvs);
    });
    e.removed.forEach(() => {
      updateLocalVideoStream(null);
      removeLocalVideoStream();
    });
  };

  const rpUpdatedCallback = (e: StateUpdate<RemoteParticipant>) => {
    e.added.forEach((remoteParticipant: RemoteParticipant) => {
      subscribeToRemoteParticipant(remoteParticipant);
    });
    e.removed.forEach((remoteParticipant: RemoteParticipant) => {
      console.log('Remote participant removed from the call.');
    });
  };

  const screenShareStateCallback = () => {
    console.log(`Local screen share state: ${call?.isScreenSharingOn}`);
  };

  const subscribeToCall = (call: Call) => {
    try {
      call.on('stateChanged', () => {
        stateChangedCallback(call);
      });

      call.localVideoStreams.forEach(async (lvs) => {
        updateLocalVideoStream(lvs);
        await displayLocalVideoStream(lvs);
      });

      call.on('localVideoStreamsUpdated', (e) => {
        lvsUpdatedCallback(e);
      });

      call.remoteParticipants.forEach((rp) => {
        subscribeToRemoteParticipant(rp);
      });

      call.on('remoteParticipantsUpdated', (e) => {
        rpUpdatedCallback(e);
      });

      call.on('isScreenSharingOnChanged', () => {
        screenShareStateCallback();
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteParticipant = (
    remoteParticipant: RemoteParticipant
  ) => {
    try {
      remoteParticipant.on('stateChanged', () => {
        console.log(
          `Remote participant state changed: ${remoteParticipant.state}`
        );
      });
      remoteParticipant.videoStreams.forEach((remoteVideoStream) => {
        subscribeToRemoteVideoStream(remoteVideoStream);
      });

      remoteParticipant.on('videoStreamsUpdated', (e) => {
        e.added.forEach((remoteVideoStream) => {
          subscribeToRemoteVideoStream(remoteVideoStream);
        });
        e.removed.forEach((remoteVideoStream) => {
          console.log('Remote participant video stream was removed.');
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const subscribeToRemoteVideoStream = async (
    remoteVideoStream: RemoteVideoStream
  ) => {
    let renderer = new VideoStreamRenderer(remoteVideoStream);
    let view: VideoStreamRendererView;
    let remoteVideoContainer = document.createElement('div');

    const createView = async () => {
      view = await renderer.createView();
      remoteVideoContainer.appendChild(view.target);
      if (remoteVideosGallery)
        remoteVideosGallery.appendChild(remoteVideoContainer);
    };

    remoteVideoStream.on('isAvailableChanged', async () => {
      try {
        if (remoteVideoStream.isAvailable) {
          await createView();
        } else {
          view.dispose();
          if (remoteVideosGallery)
            remoteVideosGallery.removeChild(remoteVideoContainer);
        }
      } catch (e) {
        console.error(e);
      }
    });

    if (remoteVideoStream.isAvailable) {
      try {
        await createView();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const displayLocalVideoStream = async (lvs: LocalVideoStream) => {
    try {
      if (lvs) {
        const lvsRenderer = new VideoStreamRenderer(lvs);
        const view = await lvsRenderer.createView();
        if (localVideoContainer && view) {
          updateLocalVideoStreamRenderer(lvsRenderer);
          localVideoContainer.appendChild(view.target);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const videoOnClick = async () => {
    try {
      if (call && !localVideoStream) {
        const lvs = await createLocalVideoStream();
        if (lvs) await call.startVideo(lvs);
      } else if (call && localVideoStream) {
        await call.stopVideo(localVideoStream);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const screenShareOnClick = async () => {
    try {
      if (call && !call.isScreenSharingOn) {
        await call.startScreenSharing();
      } else if (call && call.isScreenSharingOn) {
        await call.stopScreenSharing();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const microphoneOnClick = async () => {
    try {
      if (call && !call.isMuted) {
        await call.mute();
        updateCall(call);
      } else if (call && call.isMuted) {
        await call.unmute();
        updateCall(call);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createLocalVideoStream = async () => {
    if (deviceManager) {
      const camera = (await deviceManager?.getCameras())[0];
      if (camera) {
        return new LocalVideoStream(camera);
      } else {
        console.error('No camera detected');
      }
    }
  };

  const removeLocalVideoStream = async () => {
    try {
      localVideoStreamRenderer?.dispose();
    } catch (error) {
      console.error(error);
    }
  };

  const leaveGroupCallHandler = async () => {
    if (call) {
      unsubscribeToCall();
      await call.hangUp();
      updateCall(null);
    }
  };

  const unsubscribeToCall = () => {
    call?.off('stateChanged', () => {
      stateChangedCallback(call);
    });
    call?.off('localVideoStreamsUpdated', (e) => {
      lvsUpdatedCallback(e);
    });
    call?.off('remoteParticipantsUpdated', (e) => {
      rpUpdatedCallback(e);
    });
    call?.off('isScreenSharingOnChanged', () => {
      screenShareStateCallback();
    });
  };

  return (
    <>
      <h1 className="text-3xl font-bold text-white">
        Hi {displayName ?? 'Guest'}, welcome to Azure Communication Services :')
      </h1>
      <form className="my-5" onSubmit={saveDisplayName}>
        <label className="block">
          <span className="block text-sm font-medium text-white">
            Display Name:
          </span>
          <input
            type="text"
            className="mt-1 block w-4/12 px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            name="displayName"
            disabled={!!displayName}
          />
        </label>
        <button
          className="my-2 px-8 py-2 bg-sky-500 hover:bg-sky-700 rounded-md text-white font-medium text-sm disabled:cursor-not-allowed disabled:bg-gray-500"
          type="submit"
          disabled={!!displayName}
        >
          Save
        </button>
      </form>
      {loading && !callAgent ? (
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-sky-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="sky-600"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="white"
          />
        </svg>
      ) : callAgent ? (
        <button
          className="my-2 px-8 py-2 bg-sky-600 hover:bg-sky-700 rounded-md text-white font-medium text-sm mr-4 disabled:cursor-not-allowed disabled:bg-gray-500"
          type="button"
          disabled={call?.state === 'Connected'}
          onClick={joinGroupCallHandler}
        >
          Join Group Call
        </button>
      ) : (
        <></>
      )}

      {call && call.state === 'Connected' && (
        <div className="my-5">
          <h3 className="text-white font-bold">Call Controls:</h3>
          <button
            className="my-2 px-8 py-2 bg-teal-600 hover:bg-teal-700 rounded-md text-white font-medium text-sm mr-2"
            onClick={videoOnClick}
          >
            {localVideoStream ? 'Stop video' : 'Start video'}
          </button>
          <button
            className="my-2 px-8 py-2 bg-teal-600 hover:bg-teal-700 rounded-md text-white font-medium text-sm mr-2"
            onClick={microphoneOnClick}
          >
            {isMuted ? 'Unmute' : 'Mute'}
          </button>
          <button
            className="my-2 px-8 py-2 bg-teal-600 hover:bg-teal-700 rounded-md text-white font-medium text-sm mr-2"
            onClick={screenShareOnClick}
          >
            {call.isScreenSharingOn
              ? 'Stop screen sharing'
              : 'Start screen sharing'}
          </button>
          <button
            className="my-2 px-8 py-2 bg-red-600 hover:bg-red-700 rounded-md text-white font-medium text-sm mr-4 disabled:cursor-not-allowed disabled:bg-gray-500"
            type="button"
            onClick={leaveGroupCallHandler}
          >
            Leave group call
          </button>
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        <div id="localVideoContainer"></div>
        <div id="remoteVideoGallery"></div>
      </div>
    </>
  );
};

export default App;
