import {
  Call,
  CallAgent,
  CallState,
  DeviceManager,
  LocalVideoStream,
  VideoStreamRenderer,
} from '@azure/communication-calling';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ICall {
  callAgent: CallAgent | null;
  deviceManager: DeviceManager | null;
  call: Call | null;
  callState: CallState | null;
  localVideoStream: LocalVideoStream | null;
  localVideoStreamRenderer: VideoStreamRenderer | null;
  groupCallId: string;
  isMuted: boolean;
  updateCall: (call: Call | null) => void;
  updateCallState: (callState: CallState | null) => void;
  updateLocalVideoStream: (localVideoStream: LocalVideoStream | null) => void;
  updateLocalVideoStreamRenderer: (
    localVideoStreamRenderer: VideoStreamRenderer
  ) => void;
}

export const useCallStore = create<ICall>()(
  devtools((set, get) => ({
    callAgent: null,
    deviceManager: null,
    call: null,
    callState: null,
    localVideoStream: null,
    localVideoStreamRenderer: null,
    groupCallId: '05576221-5c1c-4adc-ae3e-5f80780aefe1', //temp
    isMuted: false,
    updateCall: (call: Call | null) =>
      set({ call: call, isMuted: call?.isMuted || false }),
    updateCallState: (callState: CallState | null) =>
      set({ callState: callState }),
    updateLocalVideoStream: (localVideoStream: LocalVideoStream | null) =>
      set({ localVideoStream: localVideoStream }),
    updateLocalVideoStreamRenderer: (
      localVideoStreamRenderer: VideoStreamRenderer
    ) => set({ localVideoStreamRenderer: localVideoStreamRenderer }),
  }))
);
