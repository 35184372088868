import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { CallClient } from '@azure/communication-calling';
import { useAuthStore } from '../store/useAuthStore';
import { useCallStore } from '../store/useCallStore';

const initCallClient = async () => {
  const { displayName, accessToken } = useAuthStore.getState();

  if (accessToken && displayName) {
    try {
      const tokenCredential = new AzureCommunicationTokenCredential(
        accessToken
      );
      const callClient = new CallClient();
      const callAgent = await callClient.createCallAgent(tokenCredential, {
        displayName: displayName,
      });
      const deviceManager = await callClient.getDeviceManager();

      useCallStore.setState({ callAgent: callAgent });
      useCallStore.setState({ deviceManager: deviceManager });
    } catch (error) {
      console.error(error);
    }
  }
};

export { initCallClient };
