import { CommunicationIdentityClient } from '@azure/communication-identity';
import { useAuthStore } from '../store/useAuthStore';

const createACSUser = async () => {
  //const { setAccessToken } = useAuthStore.getState();

  const communicationIdentityClient = new CommunicationIdentityClient(
    `endpoint=${process.env.REACT_APP_ENDPOINT};accessKey=${process.env.REACT_APP_KEY}`
  );

  try {
    const identity = await communicationIdentityClient.createUserAndToken([
      'voip',
    ]);

    const { token, user } = identity;

    //setAccessToken(token);

    useAuthStore.setState({ accessToken: token });

    //setACSId(user.communicationUserId);
  } catch (error) {
    console.error(error);
  }
};

export { createACSUser };
