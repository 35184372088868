import { create } from 'zustand';

interface IAuth {
  accessToken: string | null;
  acsId: string | null;
  tokenCredential: string | null;
  displayName: string | null;
  updateDisplayName: (name: string) => void;
  //setAccessToken: (token: string) => void;
}
export const useAuthStore = create<IAuth>((set, get) => ({
  accessToken: null,
  acsId: null,
  tokenCredential: null,
  displayName: null,
  updateDisplayName: (name: string) => set({ displayName: name }),
  //setAccessToken: (token: string) => set({ accessToken: token }),
}));
